.main[data-v-3dc6bf0a] {
  width: 98%;
  margin: 0 auto;
  margin-top: 10px;
}
.top_btns[data-v-3dc6bf0a] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon[data-v-3dc6bf0a] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-3dc6bf0a] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-3dc6bf0a] {
  display: inline-block;
  font-size: 13px;
}
.content_data[data-v-3dc6bf0a] {
  width: 98%;
  margin: 10px auto;
  background-color: #d8ecff;
}
.each[data-v-3dc6bf0a] {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.icon_bg[data-v-3dc6bf0a] {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
[data-v-3dc6bf0a] .el-progress-circle {
  width: 40px !important;
  height: 40px !important;
}
[data-v-3dc6bf0a] .el-progress__text {
  font-size: 13px !important;
  min-width: 40px !important;
}
.count[data-v-3dc6bf0a] {
  display: inline-block;
  margin-left: 10px;
  color: #999;
}
.count[data-v-3dc6bf0a]::before {
  content: "";
  background: #c4c3c3;
  display: inline-block;
  width: 1px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.operation_btn[data-v-3dc6bf0a] {
  margin-left: 5px;
  padding: 5px 8px;
}
.hint[data-v-3dc6bf0a] {
  margin-left: 5px;
  font-size: 14px;
  color: red;
}
.hint .icon-gantanhao1[data-v-3dc6bf0a] {
  font-size: 13px;
  margin-right: 3px;
  color: red;
}