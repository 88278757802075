.gary_box[data-v-647d21f8] {
  background: #f8f8f8;
  text-align: right;
  padding-right: 20px !important;
}
.blue_box[data-v-647d21f8] {
  background: #EBF5FF;
}
.el-date-editor.el-input[data-v-647d21f8], .el-date-editor.el-input__inner[data-v-647d21f8] {
  width: 180px !important;
}
.save_box[data-v-647d21f8] {
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content[data-v-647d21f8] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content span[data-v-647d21f8] {
  line-height: 28px;
  font-size: 14px;
}
.content tr[data-v-647d21f8] {
  padding: 0;
  margin: 0;
}
.content td[data-v-647d21f8] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 28px;
  padding: 0 6px;
  color: #666;
  font-size: 12px;
}
.content input[data-v-647d21f8] {
  width: 100%;
  line-height: 28px;
  text-align: right;
  font-size: 12px;
}
.center[data-v-647d21f8] {
  text-align: center;
}
.big_box4[data-v-647d21f8] {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}